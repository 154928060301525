import { MEDIUM_LONG_STAY, SHORT_STAY } from '../constants';

const getApartmentsByStay = (activity, residence) => {
  switch (activity) {
    case MEDIUM_LONG_STAY:
      return residence?.apartments;
    case SHORT_STAY:
      return residence?.apartmentsShortStay;
    default:
      return [];
  }
};

export default getApartmentsByStay;
